import { useRef, useEffect, useState } from "react"
import "./App.css"
import * as THREE from "three"
import { Camera, Object3D, AnimationAction } from "three"
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer"
import { SMAAPass } from "three/examples/jsm/postprocessing/SMAAPass"
//import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper"
import { RectAreaLightUniformsLib } from "three/examples/jsm/lights/RectAreaLightUniformsLib"

const WIDTH = 1920 / 2
const HEIGHT = 1200 / 2

//function illuminate(light: Object3D): Object3D {
//console.log(light.scale)
//const rectLight = new THREE.RectAreaLight(
//0xffffff,
//30,
//1,
//0.5
////light.scale.x / 100,
////light.scale.y / 100,
//)
//rectLight.position.copy(light.position)
//rectLight.lookAt(0, 0, 0)
//const rectLightHelper = new RectAreaLightHelper(rectLight)
//rectLight.add(rectLightHelper)
//return rectLight
//}

const pi = Math.PI

const degToRad = (degrees: number) => (degrees * pi) / 180

const clamp = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(value, max))

function init(
  canvas: HTMLCanvasElement,
  setAction: (action: AnimationAction) => void
) {
  const clock = new THREE.Clock()
  const scene = new THREE.Scene()
  RectAreaLightUniformsLib.init()

  let camera: Camera | undefined = undefined

  let mixer: any = undefined
  let card: any = undefined

  const renderer = new THREE.WebGLRenderer({
    canvas,
    alpha: true,
    antialias: true,
  })
  renderer.setSize(WIDTH, HEIGHT)

  renderer.physicallyCorrectLights = true
  renderer.outputEncoding = THREE.LinearEncoding
  renderer.shadowMap.enabled = true
  renderer.shadowMap.type = THREE.PCFSoftShadowMap
  renderer.toneMapping = THREE.ACESFilmicToneMapping
  renderer.toneMappingExposure = 0.12
  renderer.setPixelRatio(window.devicePixelRatio)

  let composer: any = new EffectComposer(renderer)

  const pass = new SMAAPass(WIDTH, HEIGHT)

  composer.addpass?.(pass)

  let mousedown: boolean = false

  canvas.addEventListener("mousedown", (e) => {
    if (e.button === 0) {
      mousedown = true
    }
  })

  window.addEventListener("mouseup", (e) => {
    if (e.button === 0) {
      mousedown = false
    }
  })

  let dx = 0
  //let dy = 0

  var start = { x: 0, y: 0 }

  canvas.addEventListener("touchstart", (e) => {
    start.x = e.touches[0].pageX
    start.y = e.touches[0].pageY
  })

  canvas.addEventListener("touchmove", (e) => {
    const offset = {
      x: start.x - e.touches[0].pageX,
      y: start.y - e.touches[0].pageY,
    }

    dx = -offset.x / 10000
    //dy = -offset.y / 10000
  })

  canvas.addEventListener("mousemove", (e) => {
    if (mousedown) {
      //console.log(e.movementX)
      dx = e.movementX / 500
      //dy = e.movementY / 500
    }
  })

  let controls: any = undefined

  const animate = () => {
    requestAnimationFrame(animate)
    const delta = clock.getDelta()
    //card.rotation.x += dy
    //card.rotation.x = clamp(card.rotation.x, degToRad(-45), degToRad(45))
    card.rotation.y += dx
    card.rotation.y = clamp(card.rotation.y, degToRad(-360), degToRad(360))
    if (card.rotation.y === degToRad(360)) {
      card.rotation.y = 0
    }
    if (card.rotation.y === degToRad(-360)) {
      card.rotation.y = 0
    }
    dx = 0
    //dy = 0
    controls?.update()
    if (camera) {
      //composer.render()
      renderer.render(scene, camera)
    }
    mixer?.update(delta)
  }

  const loader = new GLTFLoader()
  loader.load("card.gltf", (gltf: GLTF) => {
    camera = gltf.cameras[0]
    //composer.addPass?.(new RenderPass(scene, camera))

    controls = new OrbitControls(camera, renderer.domElement)
    controls.update()

    card = gltf.scene.children.filter((e) => e.name === "Card")[0]
    const background: Object3D = gltf.scene.children.filter(
      (e) => e.name === "Background"
    )[0]
    //console.log("BACKGROUND", background)
    //card.castShadow = true
    background.receiveShadow = true
    //background.recieveShadow = true
    //console.log("VISA", gltf.scene.children)
    const lights = gltf.scene.children.filter((e) => e.name.match(/Point/))
    console.log("LIGHTS", lights)
    for (const light of lights) {
      const bulb: any = light.children[0]
      bulb.castShadow = true
      bulb.shadow.mapSize.width = 1024 // default
      bulb.shadow.mapSize.height = 1024 // default
      bulb.shadow.radius = 0.1
    }
    //for (const light of lights) {
    //console.log(light);
    //light.shadow.mapSize.width
    //light.shadow.mapSize.width = 256; // default
    //light.shadow.mapSize.height = 256; // default
    //bulb.shadow.camera.near = 0.1; // default
    //bulb.shadow.camera.far = 100; // default
    //scene.add(illuminate(light))
    //}
    mixer = new THREE.AnimationMixer(card)
    const action = mixer.clipAction(gltf.animations[0])
    action.clampWhenFinished = true
    action.setLoop(THREE.LoopOnce)
    action.play()
    setAction(action)
    scene.add(gltf.scene)
    scene.traverse((object: any) => {
      if (object.isMesh === true && object.material.map !== null) {
        object.material.map.anisotropy = renderer.getMaxAnisotropy()
      }
    })

    animate()
  })
}

function App() {
  const canvas = useRef(null)
  const [action, setAction] = useState<AnimationAction | null>(null)
  useEffect(() => {
    canvas.current && init(canvas.current, setAction)
  }, [canvas])
  return (
    <div className="App">
      <img src="heartykitchens.svg" alt="partner logo" />
      <button
        onClick={() => {
          action?.reset()
          action?.play()
        }}
      >
        ▶
      </button>
      <canvas onMouseMove={() => {}} ref={canvas} id="viewport"></canvas>
    </div>
  )
}

export default App
